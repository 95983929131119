<template>
  <div class="hello">
    <div class="helloNav">
      <ul>
        <li class="techLi" v-for="item in techs" :key="item">{{item}}</li>
      </ul>
    </div>
    <div class="helloContent">
      <div class="helloTop">
        <div>个人网站</div>
        <div>待建设......(个人学习为主)</div>
      </div>
      <div class="helloMid" :style="{'background': colors[colorIndex]}">
        <img class="fImg" src="../assets/f.jpg">
      </div>
      <div class="helloFooter">
        © 个人网站 Fjsxzzr 闽ICP备19001431号-1
        <a target="_blank" href="https://beian.miit.gov.cn/">闽ICP备19001431号-1</a>
      </div>
      <button class="autoButton okBtn">同意</button>
      <button class="autoButton cancelBtn" @mouseenter="change($event)"
              :style="{'top': top, 'left': left}"
      >不同意</button>
    </div>
  </div>
</template>

  <script>
    export default {
      name: 'HelloWorld',
      props: {
        msg: String
      },
      data() {
        return {
          colorIndex: 0,
          colors: [
            "#F0FA46", "#FAED46", "#FAC946",
            "#FAC146", "#FA9D46", "#FA8946"
          ],
          techs: [
            "Vue", "Angular", "JavaScript", "JQuery", "LayUI", "ElementUI",
            "Ionic", "Cordova", "Uniapp", "Java", "SSH", "SSM", "SpringBoot",
            "WeChat"
          ],
          top: "0px",
          left: "100px"
        }
      },
      created() {
        setInterval(() => {
          this.colorIndex++
          if(this.colorIndex >= this.colors.length) {
            this.colorIndex = 0
          }
        }, 500)
      },
      methods: {
        change() {
          this.left = (window.screen.availWidth / 2 * Math.random()) + "px"
          this.top = (window.screen.availHeight / 2 * Math.random()) + "px"
          console.log(this.left + ","+this.top)
        }
      }
    }
  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .hello {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: row;
    }
    .helloNav {
      flex: 0 0 200px;
      min-width: 200px;
      max-width: 200px;
      width: 200px;
      height: 100%;
      padding: 16px;
      background: white;
      box-sizing: border-box;
    }
    .helloContent {
      flex: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .helloTop {
      width: 100%;
      flex: 0 0 80px;
      padding-top: 20px;
      box-sizing: border-box;
      background: white;
    }
    .helloMid {
      flex: auto;
      background: white;
      padding: 16px;
      overflow: hidden;
      text-align: center;
      opacity: 0.6;
      box-sizing: border-box;
    }
    .helloFooter {
      width: 100%;
      flex: 0 0 50px;
      text-align: center;
      color: white;
      box-sizing: border-box;
    }
    .fImg {
      height: 100%;
      mix-blend-mode: multiply;
    }
    .techLi {
      margin-top: 20px;
    }
    .autoButton {
      position: absolute;
    }
    .okBtn {
      top: 0;
      left: 0;
    }
    .cancelBtn {
      top: 0;
      left: 100px;
    }
  </style>
